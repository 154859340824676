import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { useTranslation } from "react-i18next"
import {
  footerHuaweiLinks,
  footerDisclaimerLinks,
  huaweiCompanyName,
  footerOtherProductsLinks,
  footerAddress,
  footerSocialLinks,
} from "../../constants/footerLinks"
import { StaticImage } from "gatsby-plugin-image"
import LocalLink from "../Common/LocalLink"

const FooterContainer = tw.footer`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24 pb-5 lg:pb-5`
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-between md:justify-between -mt-12`

const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`
const ColumnHeading = tw.h5`uppercase font-bold mb-2 lg:mb-6`

/** First part of footer */
const CVLogoColumn = tw(
  Column
)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`
const CVLogoColumnContainer = tw(LocalLink)`max-w-64 mx-auto lg:mx-0 `

const LinkList = tw.ul`mt-6 text-sm font-medium`
const LinkListItem = tw.li`mt-3`
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition[all] duration-300`

const AddressText = tw.p`mb-3 text-sm font-medium text-gray-600`

const SocialLinksContainer = tw.div`mt-8 md:mt-8 flex`
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition[all] duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`

const Divider = tw.div`my-12 border-b-2 border-gray-300 w-full`

/** Second part of footer */
const SecondPartRow = tw.div`flex flex-col flex-wrap justify-center items-center`
const CopywrightNotice = tw.p`text-center text-sm mx-5 sm:text-base md:mt-0 font-medium text-gray-500`
const DisclaimerRow = tw.div`flex flex-row flex-wrap m-5 items-center justify-center`
const DisclaimerLink = tw(
  LocalLink
)`text-center text-sm m-2 md:mt-0 font-normal text-gray-500
border-b-2 border-transparent hover:border-gray-500 pb-1 transition[all] duration-300
`

const Footer = () => {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  return (
    <FooterContainer>
      <Content>
        <SixColumns>
          <CVLogoColumn>
            <CVLogoColumnContainer to={"gdpr"}>
              {language === "en" ? (
                <StaticImage
                  css={tw`w-auto h-auto xs:mx-auto md:mx-0`}
                  placeholder="none"
                  src="../../images/KVKK-en.png"
                  backgroundColor="transparent"
                  alt=""
                />
              ) : (
                <StaticImage
                  css={tw`w-auto h-auto xs:mx-auto md:mx-0`}
                  placeholder="none"
                  backgroundColor="transparent"
                  src="../../images/KVKK-tr.png"
                  alt=""
                />
              )}
            </CVLogoColumnContainer>
          </CVLogoColumn>
          <Column>
            <ColumnHeading>{t("FOOTER_ADDRESS")}</ColumnHeading>
            {footerAddress.map(addr => (
              <AddressText key={addr.id}>{addr.label}</AddressText>
            ))}
          </Column>

          <Column>
            <ColumnHeading>{t("FOOTER_LINKS")}</ColumnHeading>
            <LinkList>
              {footerHuaweiLinks.map(link => (
                <LinkListItem key={link.id}>
                  <Link href={link.path} target="_blank">
                    {t(link.label)}
                  </Link>
                </LinkListItem>
              ))}
            </LinkList>
            <SocialLinksContainer>
              {footerSocialLinks.map(link => (
                <SocialLink key={link.id} href={link.path} target="_blank">
                  {link.icon}
                </SocialLink>
              ))}
            </SocialLinksContainer>
          </Column>
          <Column>
            {/* <ColumnHeading>{t("FOOTER_OTHER_PRODUCTS")}</ColumnHeading>
            <LinkList>
              {footerOtherProductsLinks.map(link => (
                <LinkListItem key={link.id}>
                  <Link href={link.path} target="_blank" rel="noreferrer">
                    {link.label}
                  </Link>
                </LinkListItem>
              ))}
            </LinkList> */}
          </Column>
        </SixColumns>
        <Divider />
        <SecondPartRow>
          <StaticImage
            css={tw`w-auto h-auto mb-8 md:mb-10`}
            src="../../images/logos/huawei_logo.png"
            placeholder="none"
            alt=""
          />
          <CopywrightNotice>{huaweiCompanyName}</CopywrightNotice>
          <DisclaimerRow>
            {footerDisclaimerLinks.map(link => (
              <DisclaimerLink
                key={link.id}
                to={link.path}
                isDefault={link.defaultPath}
              >
                {t(`${link.label}`)}
              </DisclaimerLink>
            ))}
          </DisclaimerRow>
        </SecondPartRow>
      </Content>
    </FooterContainer>
  )
}

export default Footer
